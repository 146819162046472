<template>
  <div class="loginDetail">
    <div class="loginOuter">
      <img v-if="data.password" class="loginQr" :src="qrURL"><h4>EdShed</h4><small>https://play.edshed.com</small><br><b>Name:</b> {{ data.name }}<br><b>Username:</b> {{ data.username }}<br><b v-if="data.password">Password:</b> <span v-if="data.password">{{ data.password || '******' }}</span>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  name: 'LoginDetails',
  props: ['data'],
  data (router) {
    return {
      qrURL: ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.renderQRCode()
    })
  },
  methods: {
    renderQRCode () {
      const codeString = 'spellingshedlogin://' + btoa(this.data.username) + ':' + btoa(this.data.password)
      const that = this
      QRCode.toDataURL(codeString, function (error, url) {
        if (error) {
          console.error(error)
        }
        that.qrURL = url
      })
    }
  }
}
</script>

<style scoped>

  .loginDetail {
     display:inline-grid;

  }

  h4 {
    font-weight: bold;
    margin: 3px 0 !important;
  }

  .loginOuter {
    border: 1px solid black;
    width: 300px;
    float:left;
    margin:5px;
    padding: 5px;
    background: white;
    font-size:14px;
  }

  .loginQr {
    float:right;
    width: 90px;
    height: 90px;
  }
</style>
