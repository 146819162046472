import JsPDF from 'jspdf'
import QRCode from 'qrcode'

export default {
  generatePDFForUsers (users) {
    const doc = new JsPDF()
    doc.setFont('sans-serif')
    doc.setFontSize(22)
    doc.text(105, 20, 'EdShed - Pupil Login Details', null, null, 'center')
    doc.setFontSize(14)
    doc.text(15, 30, ['Print these to give to pupils for easy reference. The QR code can be scanned in our', 'app to login. Do not share images of these QR codes or others will be able to gain', 'access to these pupil accounts.'])

    for (let i = 0; i < users.length; i++) {
      const numOnPage = 14
      const firstPageOffset = 2
      let x = 15 + (i % 2) * 95
      let y = -30 + 40 * Math.ceil((((i + firstPageOffset) % numOnPage) + 1) / 2)
      doc.rect(x, y, 85, 35)
      doc.setFontSize(14)
      doc.text(x + 3, y + 6, 'Spelling Shed')
      doc.setFontSize(8)
      doc.text(x + 3, y + 11, 'https://play.edshed.com')
      doc.setFontSize(12)
      doc.text(x + 3, y + 18, 'Name: ' + users[i].name)
      doc.text(x + 3, y + 25, 'Username: ' + users[i].username)
      doc.text(x + 3, y + 32, 'Password: ' + users[i].password)
      const codeString = 'spellingshedlogin://' + btoa(users[i].username) + ':' + btoa(users[i].password)
      QRCode.toDataURL(codeString, function (error, url) {
        if (error) {
          console.error(error)
        }
        doc.addImage(url, 'png', x + 54, y + 1, 30, 30)
      })
      if (((i + firstPageOffset) % numOnPage) === numOnPage - 1) {
        doc.addPage()
      }
    }
    doc.save('pupil-logins.pdf')
  }
}
